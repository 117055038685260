"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "MetaTags", {
  enumerable: true,
  get: function get() {
    return _metaTags.MetaTags;
  }
});

var _metaTags = require("./meta-tags");