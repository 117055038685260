"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLocale = void 0;

var _react = require("react");

var _contexts = require("../contexts");

var useLocale = function useLocale() {
  return (0, _react.useContext)(_contexts.LocaleContext);
};

exports.useLocale = useLocale;