import React, { useMemo } from "react";

import { Section, Container, Title, Button, Markdown, getProperty } from "@lachevaliniere/shared";
import { useStaticShared } from "hooks";

import styles from "./directions.module.scss";

// https://developers.google.com/maps/documentation/urls/get-started#directions-action
const GOOGLE_MAPS_BASE_URL = "https://www.google.com/maps/dir/?api=1";

export const Directions = () => {
  const staticShared = useStaticShared();

  const title = getProperty(staticShared, "data.addressTitle");
  const latitude = getProperty(staticShared, "data.addressGeolocation.latitude");
  const longitude = getProperty(staticShared, "data.addressGeolocation.longitude");
  const addressLines = getProperty(staticShared, "data.addressLines") || [];
  const directions = getProperty(staticShared, "data.addressDirections");

  const address = useMemo(() => addressLines.map(({ line }) => line).join(", "), [addressLines]);

  const gmapsHref = useMemo(
    () => `${GOOGLE_MAPS_BASE_URL}&destination=${address}&center=${latitude},${longitude}`,
    [address, latitude, longitude]
  );

  return (
    <Section>
      <Container>
        <Title>{title}</Title>
        <div className={styles.address}>
          <Button wrapper="a" href={gmapsHref} rel="noopener noreferrer" target="_blank">
            {address}
          </Button>
        </div>
        <div className={styles.directions}>
          <Markdown content={directions} />
        </div>
      </Container>
    </Section>
  );
};
