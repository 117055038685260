"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Layout", {
  enumerable: true,
  get: function get() {
    return _layout.Layout;
  }
});

var _layout = require("./layout");