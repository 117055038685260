import React, { useEffect, useRef } from "react";

import { Layout, useLocale, getProperty } from "@lachevaliniere/shared";
import { Directions, Request } from "./src/templates/shared";

const Page = ({ children, ...props }) => {
  const localeRef = useRef(false);
  const { setLocale } = useLocale();
  const locale = getProperty(props, "pageContext.locale");

  useEffect(() => {
    if (!localeRef.current) {
      localeRef.current = true;
      return;
    }

    setLocale(locale);
  }, [setLocale, locale]);

  return (
    <Layout {...props}>
      {children}
      <Directions />
      <Request />
    </Layout>
  );
};

const wrapPageElement = ({ element, props }) => <Page {...props}>{element}</Page>;

export default wrapPageElement;
