import { graphql, useStaticQuery } from "gatsby";

import { useFlatten, getProperty } from "@lachevaliniere/shared";

export const useStaticShared = () => {
  const results = useStaticQuery(query) || {};
  const key = "squidex.querySharedContents.0";

  return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
  query useSharedQuery {
    squidex {
      querySharedContents(filter: "status eq 'Published'") {
        id
        data {
          metaCompany {
            de
            en
            fr
          }
          metaPage {
            de
            en
            fr
          }

          notFoundTitle {
            de
            en
            fr
          }
          notFoundText {
            de
            en
            fr
          }
          notFoundButton {
            de
            en
            fr
          }

          addressTitle {
            de
            en
            fr
          }

          addressGeolocation {
            iv
          }

          addressLines {
            de {
              line
            }
            en {
              line
            }
            fr {
              line
            }
          }

          addressDirections {
            de
            en
            fr
          }

          requestTitle {
            de
            en
            fr
          }

          requestText {
            de
            en
            fr
          }

          requestLink {
            iv {
              id
            }
          }

          requestLinkText {
            de
            en
            fr
          }

          requestCalendarHint {
            de
            en
            fr
          }

          requestAttachment {
            de {
              url
            }
            en {
              url
            }
            fr {
              url
            }
          }
          requestAttachmentText {
            de
            en
            fr
          }

          requestTeaser {
            de
            en
            fr
          }
        }
      }
    }
  }
`;
