import { graphql, useStaticQuery } from "gatsby";

import { useFlatten, getProperty } from "@lachevaliniere/shared";

export const useStaticContact = () => {
  const results = useStaticQuery(query) || {};
  const key = "squidex.queryContactContents.0";

  return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
  query useContactQuery {
    squidex {
      queryContactContents(filter: "status eq 'Published'") {
        id
        data {
          metaTitle {
            de
            en
            fr
          }
          metaDescription {
            de
            en
            fr
          }

          formFields {
            de {
              name
              alias
              isRequired
              isHalfWidth
              type
            }
            en {
              name
              alias
              isRequired
              isHalfWidth
              type
            }
            fr {
              name
              alias
              isRequired
              isHalfWidth
              type
            }
          }
          formSubmit {
            de
            en
            fr
          }
          formHint {
            de
            en
            fr
          }
          formRecaptcha {
            de
            en
            fr
          }
          formBusyTitle {
            de
            en
            fr
          }
          formBusyMessage {
            de
            en
            fr
          }
          formSuccessTitle {
            de
            en
            fr
          }
          formSuccessMessage {
            de
            en
            fr
          }
          formFailureTitle {
            de
            en
            fr
          }
          formFailureMessage {
            de
            en
            fr
          }
        }
      }
    }
  }
`;
