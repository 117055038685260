"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.observeResize = exports.getStyleProperty = exports.getScrollOffsetY = exports.scrollTo = void 0;

var _misc = require("./misc");

//
// Scrolls to a dom element smoothly
//
var scrollTo = function scrollTo(element) {
  if (element instanceof HTMLElement) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }
}; //
// Tries to read overall scroll offset
//


exports.scrollTo = scrollTo;

var getScrollOffsetY = function getScrollOffsetY() {
  if (!_misc.isClient) {
    return 0;
  }

  return typeof window.pageYOffset === "number" ? window.pageYOffset : typeof document.documentElement.scrollTop === "number" ? document.documentElement.scrollTop : document.body.scrollTop;
}; //
// Reads a computed style value
//


exports.getScrollOffsetY = getScrollOffsetY;

var getStyleProperty = function getStyleProperty(element, property) {
  if (!_misc.isClient) {
    return null;
  }

  var computedStyles = window.getComputedStyle(element);
  return computedStyles.getPropertyValue(property);
}; //
// Tries to detect resize changes of element
//


exports.getStyleProperty = getStyleProperty;

var observeResize = function observeResize(element, callback) {
  if (typeof MutationObserver === "undefined") {
    var eventName = "DOMSubtreeModified";
    document.addEventListener(eventName, callback);
    return function () {
      return document.removeEventListener(eventName, callback);
    };
  }

  if (typeof ResizeObserver === "undefined") {
    var _observer = new MutationObserver(callback);

    _observer.observe(element, {
      characterData: true,
      attributes: true,
      childList: true,
      subtree: true
    });

    return function () {
      return _observer.disconnect();
    };
  }

  var observer = new ResizeObserver(callback);
  observer.observe(element);
  return function () {
    return observer.disconnect();
  };
};

exports.observeResize = observeResize;