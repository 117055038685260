"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ImageSlider", {
  enumerable: true,
  get: function get() {
    return _imageSlider.ImageSlider;
  }
});

var _imageSlider = require("./image-slider");