import customWrapRootElement from "./wrap-root-element";
import customWrapPageElement from "./wrap-page-element";

import { getProperty } from "@lachevaliniere/shared";

import "./src/styles/main.scss";

export const wrapRootElement = customWrapRootElement;
export const wrapPageElement = customWrapPageElement;

export const onRouteUpdate = ({ location, prevLocation }) => {
  const state = getProperty(location, "state");
  if (state) {
    location.state.referrer = prevLocation ? prevLocation.pathname : null;
  }
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  window.scrollTo(0, 0);

  const anchor = getProperty(location, "state.anchor");
  const element = document.getElementById(anchor);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }

  return false;
};
