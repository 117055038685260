import { useCallback } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { useFlatten, useLocale, getProperty, joinPaths, trimSlashes } from "@lachevaliniere/shared";

export const usePages = () => {
  const { locale, defaultLocale } = useLocale();
  const results = useStaticQuery(query) || {};
  const key = "squidex.queryPagesContents";

  const postProcessData = useCallback(
    (pages) =>
      pages.map((page) => {
        const isExternal = getProperty(page, "data.isExternal");

        if (!isExternal) {
          const localePath = locale !== defaultLocale ? `/${locale}` : "";
          const pagePath = getProperty(page, "data.path");
          const path = joinPaths([localePath, pagePath]);
          page.data.path = `/${trimSlashes(path)}`;
        }

        return page;
      }),
    [locale, defaultLocale]
  );

  return useFlatten(getProperty(results, key) || {}, postProcessData);
};

export const usePageById = (value) =>
  usePages().find((page) => {
    const pageId = getProperty(page, "id");
    return pageId === value;
  });

export const usePageByAlias = (value) =>
  usePages().find((page) => {
    const pageAlias = getProperty(page, "data.alias");
    return pageAlias === value;
  });

export const usePagesByMenu = (value) =>
  usePages().filter((page) => {
    const pageMenu = getProperty(page, "data.menu");
    return pageMenu === value;
  });

const query = graphql`
  query usePagesQuery {
    squidex {
      queryPagesContents(top: 999, filter: "status eq 'Published'", orderby: "data/rank/iv") {
        id
        data {
          menu {
            iv
          }
          alias {
            iv
          }
          path {
            de
            en
            fr
          }
          title {
            de
            en
            fr
          }
          isExternal {
            iv
          }
        }
      }
    }
  }
`;
