"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Text", {
  enumerable: true,
  get: function get() {
    return _text.Text;
  }
});
Object.defineProperty(exports, "Title", {
  enumerable: true,
  get: function get() {
    return _title.Title;
  }
});

var _text = require("./text");

var _title = require("./title");