"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.XL = exports.LG = exports.MD = exports.SM = exports.XS = void 0;

var _variables = _interopRequireDefault(require("../styles/_variables.scss"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// parse SASS variables and convert rem to px: "1rem" -> 16
var XS = 16 * parseFloat(_variables.default.breakpoint_xs);
exports.XS = XS;
var SM = 16 * parseFloat(_variables.default.breakpoint_sm);
exports.SM = SM;
var MD = 16 * parseFloat(_variables.default.breakpoint_md);
exports.MD = MD;
var LG = 16 * parseFloat(_variables.default.breakpoint_lg);
exports.LG = LG;
var XL = 16 * parseFloat(_variables.default.breakpoint_xl);
exports.XL = XL;