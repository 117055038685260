import React, { useMemo } from "react";
import { Link } from "gatsby";

import {
  Section,
  Container,
  Title,
  Text,
  Button,
  Calendar,
  getProperty,
  arrayToObject,
  BackgroundIcon,
} from "@lachevaliniere/shared";

import { useStaticShared, usePages, useReservations } from "hooks";

import styles from "./request.module.scss";

export const Request = () => {
  const pages = usePages();
  const reservations = useReservations();
  const staticShared = useStaticShared();

  const pagesById = useMemo(() => arrayToObject(pages), [pages]);
  const activeRanges = useMemo(
    () =>
      reservations.map((reservation) => ({
        start: new Date(getProperty(reservation, "data.start")),
        end: new Date(getProperty(reservation, "data.end")),
      })),
    [reservations]
  );

  const title = getProperty(staticShared, "data.requestTitle");
  const text = getProperty(staticShared, "data.requestText");
  const hint = getProperty(staticShared, "data.requestCalendarHint");
  const teaser = getProperty(staticShared, "data.requestTeaser");

  const linkId = getProperty(staticShared, "data.requestLink.0.id");
  const linkText = getProperty(staticShared, "data.requestLinkText");
  const pagePath = getProperty(pagesById, `${linkId}.data.path`);

  const attachmentUrl = getProperty(staticShared, "data.requestAttachment.0.url");
  const attachmentText = getProperty(staticShared, "data.requestAttachmentText");

  return (
    <Section color="quaternary" noPaddingTop={true} noPaddingBottom={true}>
      <div className={styles.section}>
        <Container>
          <div className={styles.container}>
            <div className={styles.content}>
              <Title>{title}</Title>
              <Text size="md">{text}</Text>
              <div className={styles.link}>
                <Button wrapper="a" href={attachmentUrl}>
                  {attachmentText}
                </Button>
              </div>
              <div className={styles.link}>
                <Button wrapper={Link} to={pagePath}>
                  {linkText}
                </Button>
              </div>
            </div>
            <div className={styles.calendar}>
              <Calendar activeRanges={activeRanges}>
                <div className={styles.legend}>
                  <Text size="sm" color="black">
                    {hint}
                  </Text>
                </div>
              </Calendar>
            </div>
            <div className={styles.teaser}>
              <Text type="special" color="primary" transform="uppercase" size="xl" weight="bold">
                {teaser}
              </Text>
            </div>
          </div>
        </Container>
        <div className={styles.background}>
          <BackgroundIcon className={styles.svg} />
        </div>
      </div>
    </Section>
  );
};
