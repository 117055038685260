import React, { useEffect, useRef, useMemo } from "react";

import {
  LocaleProvider,
  ScrollProvider,
  ThemeProvider,
  useTheme,
  getProperty,
} from "@lachevaliniere/shared";
import { usePages, usePageByAlias, useStaticShared } from "./src/hooks";

const ThemeConsumer = ({ children }) => {
  const { setPages } = useTheme();
  const pagesRef = useRef(false);
  const pages = usePages();

  useEffect(() => {
    if (!pagesRef.current) {
      pagesRef.current = true;
      return;
    }

    setPages(pages);
  }, [pages, setPages]);

  return children;
};

const ThemeWrapper = (props) => {
  const pages = usePages();
  const staticShared = useStaticShared();
  const home = usePageByAlias("home")

  const root = getProperty(home, "data.path");

  const page = getProperty(staticShared, "data.metaPage");
  const company = getProperty(staticShared, "data.metaCompany");
  const meta = useMemo(() => ({ company, page }), [company, page]);

  return (
    <ThemeProvider
      initialRoot={root}
      initialMeta={meta}
      initialPages={pages}
      initialCompany={company}>
      <ThemeConsumer {...props} />
    </ThemeProvider>
  );
};

const wrapRootElement = ({ element }) => (
  <LocaleProvider>
    <ScrollProvider>
      <ThemeWrapper>{element}</ThemeWrapper>
    </ScrollProvider>
  </LocaleProvider>
);

export default wrapRootElement;
