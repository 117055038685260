// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-chateau-jsx": () => import("./../../../src/templates/chateau.jsx" /* webpackChunkName: "component---src-templates-chateau-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-rooms-jsx": () => import("./../../../src/templates/rooms.jsx" /* webpackChunkName: "component---src-templates-rooms-jsx" */),
  "component---src-templates-surroundings-jsx": () => import("./../../../src/templates/surroundings.jsx" /* webpackChunkName: "component---src-templates-surroundings-jsx" */)
}

