"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.arrayToObject = exports.isArray = void 0;

var _object = require("./object");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var isArray = function isArray(value) {
  return Array.isArray(value);
};

exports.isArray = isArray;

var arrayToObject = function arrayToObject(array) {
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "id";
  var value = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;

  if (!isArray(array)) {
    throw new Error("\"array\" must be an array!");
  }

  return Object.assign.apply(Object, [{}].concat(_toConsumableArray(array.map(function (item) {
    var obj = {};
    var prop = typeof key === "function" ? key(item) : (0, _object.isObject)(item) ? (0, _object.getProperty)(item, key) : item;
    var val = value !== undefined ? typeof value === "function" ? value(item) : value : (0, _object.isObject)(item) ? item : true;
    obj[prop] = val;
    return obj;
  }))));
};

exports.arrayToObject = arrayToObject;