import { graphql, useStaticQuery } from "gatsby";

import { useFlatten, getProperty } from "@lachevaliniere/shared";

export const useStaticChateau = () => {
  const results = useStaticQuery(query) || {};
  const key = "squidex.queryChateauContents.0";

  return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
  query useChateauQuery {
    squidex {
      queryChateauContents(filter: "status eq 'Published'") {
        id
        data {
          metaTitle {
            de
            en
            fr
          }
          metaDescription {
            de
            en
            fr
          }

          gallery {
            de {
              caption
              image {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 1200, maxHeight: 675, quality: 64, cropFocus: CENTER) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                ...SquidexImageLocalFileProps
              }
            }
            en {
              caption
              image {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 1200, maxHeight: 675, quality: 64, cropFocus: CENTER) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                ...SquidexImageLocalFileProps
              }
            }
            fr {
              caption
              image {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 1200, maxHeight: 675, quality: 64, cropFocus: CENTER) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                ...SquidexImageLocalFileProps
              }
            }
          }

          sections {
            de {
              id
              title
              text
              images {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxHeight: 900, quality: 64) {
                      ...GatsbyImageSharpFluid_withWebp
                      presentationWidth
                    }
                  }
                }
                ...SquidexImageLocalFileProps
              }
              slides: images {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 875, maxHeight: 656, quality: 64) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                ...SquidexImageLocalFileProps
              }
              link {
                id
              }
              linkText
            }
            en {
              id
              title
              text
              images {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxHeight: 900, quality: 64) {
                      ...GatsbyImageSharpFluid_withWebp
                      presentationWidth
                    }
                  }
                }
                ...SquidexImageLocalFileProps
              }
              slides: images {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 875, maxHeight: 656, quality: 64) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                ...SquidexImageLocalFileProps
              }
              link {
                id
              }
              linkText
            }
            fr {
              id
              title
              text
              images {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxHeight: 900, quality: 64) {
                      ...GatsbyImageSharpFluid_withWebp
                      presentationWidth
                    }
                  }
                }
                ...SquidexImageLocalFileProps
              }
              slides: images {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 875, maxHeight: 656, quality: 64) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                ...SquidexImageLocalFileProps
              }
              link {
                id
              }
              linkText
            }
          }

          impressions {
            de {
              title
              caption
              image {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 825, quality: 64) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                ...SquidexImageLocalFileProps
              }
              link {
                id
              }
              anchor
            }
            en {
              title
              caption
              image {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 825, quality: 64) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                ...SquidexImageLocalFileProps
              }
              link {
                id
              }
              anchor
            }
            fr {
              title
              caption
              image {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 825, quality: 64) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                ...SquidexImageLocalFileProps
              }
              link {
                id
              }
              anchor
            }
          }
        }
      }
    }
  }
`;
