"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useFlatten", {
  enumerable: true,
  get: function get() {
    return _useFlatten.useFlatten;
  }
});

var _useFlatten = require("./use-flatten");