"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useScroll = void 0;

var _react = require("react");

var _contexts = require("../contexts");

var useScroll = function useScroll() {
  return (0, _react.useContext)(_contexts.ScrollContext);
};

exports.useScroll = useScroll;