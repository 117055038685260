"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extractLanguage = exports.detectLanguage = void 0;

var _misc = require("./misc");

var _object = require("./object");

var detectLanguage = function detectLanguage() {
  if (!_misc.isClient) {
    return null;
  }

  var _navigator = navigator,
      language = _navigator.language,
      languages = _navigator.languages,
      userLanguage = _navigator.userLanguage;
  var detected = (0, _object.getProperty)(languages, "0") || userLanguage || language;

  if (typeof detected !== "string") {
    return null;
  }

  return detected.substring(0, 2).toLowerCase();
};

exports.detectLanguage = detectLanguage;

var extractLanguage = function extractLanguage(locales) {
  if (!_misc.isClient) {
    return null;
  } //
  // 1) `/(l1|l2|l3|...)/xxx`
  // 2) `/(l1|l2|l3|...)/`
  // 3) `/(l1|l2|l3|...)`
  //


  var group = "(".concat(locales.join("|"), ")");
  var path = window.location.pathname || "";
  var regex = new RegExp("\\/".concat(group, "(?=\\/|$)"));
  var match = path.match(regex);

  if (match === null) {
    return null;
  }

  return (0, _object.getProperty)(match, "1");
};

exports.extractLanguage = extractLanguage;