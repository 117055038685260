import { graphql, useStaticQuery } from "gatsby";

import { useFlatten, getProperty } from "@lachevaliniere/shared";

export const useReservations = () => {
  const results = useStaticQuery(query) || {};
  const key = "squidex.queryReservationsContents";

  return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
  query useReservationsQuery {
    squidex {
      queryReservationsContents(top: 999, filter: "status eq 'Published'") {
        id
        data {
          start {
            iv
          }
          end {
            iv
          }
        }
      }
    }
  }
`;
